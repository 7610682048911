.CompanyTickets-container{
  width: 70%;
  height: 100%;
}
.company-dash-toplayer{
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.company-tickets-maincontainer{
  width: 95%;
  height: 140vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.clientManagement-history-labels p{
  width: 18%;
  color: rgba(0, 0, 0, 0.837);
}
.clientManagement-list-item p{
  width: 18%;
  padding-left: 2rem;
  font-weight:500;
}
.company-tickets-trip{
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0;
}
.company-tickets-trip img{
  width: 10%;
  height: 30%;
}
.company-tickets-trip p{
  width: 25%;
  padding-left: 0;
  font-weight: 600;
}
.companyTicket-details-container{
  width: 95%;
  height: 120vh;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
}
.print-btn-holder{
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}

.print-btn-holder img{
  width: 20px;
  height: 20px;
}

.company-ticket-details-mainContainer{
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.208);
  border-radius: 10px;
  justify-content: space-around;
  align-items: center;
}
.company-ticket-details-section1{
  width: 90%;
  height: 20%;
  /* background-color: aqua; */
  display: flex;
  align-items: center;
  gap: 1rem;
}
.company-ticket-details-section1 img{
  height: 60%;
}
.company-ticket-details-section2{
  width: 90%;
  height: 15%;
}
.company-ticket-details-section2-items{
  display: flex;
  width: 100%;
  height: 70%;
  
}
.company-ticket-details-section2-item{
  display: flex;
  width: 35%;
  height: 100%;
  align-items: center;
  justify-content: space-around;
}
.company-ticket-details-section2-item-img{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.company-ticket-details-section3{
  width: 90%;
  height: 50%;
}
.company-ticket-details-section4{
  width: 90%;
  height: 15%;
  /* background-color: rgb(189, 216, 216); */
  display: flex;
  align-items: center;
}
.company-ticket-details-section3-main{
  width:40% ;
  height: 100%;
  border-radius: 5px;
}
.company-ticket-details-section3-main-top{
  width: 100%;
  height: 30%;
  border: solid 1px rgba(0, 0, 0, 0.37);
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.company-ticket-details-section3-main-body{
  width: 100%;
  height: 70%;
  border-radius:  0 0 10px 10px;
  background-color: #F8F8F8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* gap: 2rem; */
}
.company-ticket-details-section3-main-body-item{
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 20%;
  gap: 10px;
}
.company-ticket-details-section3-main-body-itemList{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.company-ticket-details-section3-main-body-item label{
  font-size: 12px;
  color: rgba(0, 0, 0, 0.365);
}
.company-ticket-details-section3-main-body-itemList p{
  font-weight: 700;
}
.company-ticket-details-section3-main-body-itemList span{
  font-weight: 800;
}
.mainSpan{
  color: red;
}
.company-ticket-details-section4 img{
  height: 70%;
}
.title-holder{
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 3rem;
}
.newTicketForm{
  width: 100%;
  height: max-content;
  min-height: 80vh;
  padding-block: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.newTicketForm input{
  width: 50%;
  height: 2.8rem;
  border-radius: 5px;
  background-color: transparent;
  border: solid 1px rgba(0, 0, 0, 0.418);
  padding-left: 1rem;
}
.newTicketForm button{
width: 50%;
padding-block: 1rem;
cursor: pointer;
color: white;
background-color: red;
border-radius: 10px;
border: none;
}
.addTicket-btn{
  padding-inline: 1.5rem;
  padding-block: 0.8rem;
  border: none;
  background-color: red;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
.ticket-listItem{
  display: none;
}
.company-tickets-maincontainer-mobile{
  display: none;
}


@media only screen and (min-width:320px) and (max-width:768px) {
  .companyTicket-details-container{
    width: 95%;
  }
  .CompanyTickets-container{
    width: 90%;
  }
  .clientManagement-history-labels2{
    display: none;
  }
  .ticket-listItem{
    display: flex;
    width: 90%;
    height: 30vh;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.416);
    display: flex;
    flex-direction: column;
    padding-inline: 1rem;
  }
  .newTicketForm input{
    width: 95%;
    height: 4rem;
  }
  .newTicketForm{
    gap: 1rem;
  }
  .company-tickets-maincontainer-mobile{
    display: flex;
    width: 100%;
    height: max-content;
  }
  .clientManagement-history{
    display: none;
  }
  .company-ticket-details-mainContainer{
    width: 100%;
  }
  .companyTicket-details-container{
    width: 100%;
  }
  .company-ticket-details-section2-item img{
    display: none;
  }
  .company-ticket-details-section2-item{
    font-size: 14px;
    width: 40%;
  }
  .company-ticket-details-section2-item h2{
    font-size: 14px;
  }
  .company-ticket-details-section2-item-img{
    width: 10%;
  }
  .company-ticket-details-section3-main{
    width: 90%;
  }
}