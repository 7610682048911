.companyVehicles-mainContainer{
    width: 70%;
    height: 100%;
}
.companyVehicles-section1{
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.companyVehicles-section1 button{
    background-color: red;
    color: white;
    padding-block: 0.7rem;
    padding-inline: 1.5rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.companyVehicles-section2{
    width: 100%;
    height: max-content;
    min-height: 30vh;
}
.driverList-label-holder{
    width: 100%;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px rgba(0, 0, 0, 0.256);
    padding-inline: 2rem;
    color: rgba(0, 0, 0, 0.418);
}
.vehicle-image-label{
    width: 30%;
}
.vehicle-label{
    width: 15%;
}
.vehicle-menuHolder{
    width: 10%;
}
.vehicle-list-holder{
    width: 100%;
    height: 15vh;
    display: flex;
    
}
.vehicle-list-item{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 2rem;
    border-bottom: solid 1px rgba(0, 0, 0, 0.256);
    font-weight: 600;
}
.vehicle-image{
    width: 30%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vehicle-image2{
    width: 30%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vehicle-label{
    width: 15%;
}
.vehicle-menuHolder{
    width: 10%;
}
.vehicle-image img{
    width: 60%;
    height: 90%;
    border-radius: 10px;
}
.vehicle-image2 img{
    width: 30%;
    height: 90%;
    border-radius: 10px;
}
.add-newVehicle-conatiner{
    width: 100%;
    height: max-content;
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}
.add-newVehicle-conatiner h2{
    width: 100%;
}
.newVehicle-imgHolder{
    width: 100%;
    height: 20vh;
    display: flex;
    align-items: center;
}
.newVehicle-imgHolder img{
    width: 20%;
    height: 80%;
    border: none;
    border-radius: 10px;
    display: flex;
    background-color: bisque;
}
.AddcompanyVehicles-item{
    width: 100%;
    height: 12vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.AddcompanyVehicles-item input{
    width: 90%;
    height: 60%;
    border: none;
    border-radius: 5px;
    box-shadow:0 0 5px rgba(0, 0, 0, 0.357);
    padding-left: 1rem;
}

.AddcompanyVehicles-item2 button{
    padding-inline: 1.5rem;
    padding-block: 0.8rem;
    background-color: red;
    color: white;
    border: none;
    /* width: 15%; */
    border-radius: 5px;
    cursor: pointer;
}
.company-tickets-maincontainer-mobile{
    display: none;
}
.company-tickets-maincontainer-mobile-sec2{
    display: none;
}
@media only screen and (min-width:320px) and (max-width:768px) {
    .companyVehicles-mainContainer{
        width: 90%;
    }
    .company-tickets-maincontainer{
        width: 100%;
    }
    .AddcompanyVehicles-item input{
        width: 100%;
    }
    .companyVehicles-section1{
        display: none;
    }
    .company-tickets-maincontainer{
        display: none;
    }
    .company-tickets-maincontainer-mobile{
        display: flex;
        height: 60vh;
        width: 100%;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
    }
    .company-tickets-maincontainer-mobile-sec1{
        width: 90%;
        height: 8vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .company-tickets-maincontainer-mobile-sec1a{
        width: 60%;
        height: 100%;
        border-radius: 30px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.223);
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .company-tickets-maincontainer-mobile-sec1a input{
        width: 70%;
        background-color: transparent;
        border: none;
    }
    .company-tickets-maincontainer-mobile-sec1b{
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .company-tickets-maincontainer-mobile-sec2{
        display: flex;
        gap: 0.5rem;
        width: 100%;
        height: max-content;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        overflow-y: auto;
    }
    .company-tickets-maincontainer-mobile-sec2a{
        width:90%;
        height: 40vh;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.223);
        display: flex;
        align-items: center;
        justify-items: center;
        border-radius: 20px;
        flex-direction: column;
    }
    .company-tickets-maincontainer-mobile-sec2a1{
        width: 100%;
        height: 20%;
        border-top: solid 1px rgba(0, 0, 0, 0.389);
        padding-inline: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .company-tickets-maincontainer-mobile-sec2a2{
        width: 100%;
        height: 25%;
        padding-inline: 1rem;
        display: flex;
        align-items: center;
    }
    .company-tickets-maincontainer-mobile-sec2a2 img{
        height: 80%;
        width: 30%;
    }
}